import axios from 'axios';
import _ from 'lodash';
import {state} from '../stores/index';
import {applyColors, setCurrency, formatUnderscoreField, map} from '../utils/utils';

export var ajax = axios.create({
  baseURL: `${state.get().urlBase}/api/`,
  timeout: 7200000,
  xsrfCookieName: 'csrftoken'
});

ajax.interceptors.response.use((response) => {
  if (typeof response.data.alert !== 'undefined') {
    let isWarning =  response.data.alert.indexOf('warning') > -1;
    state.setAlert({
      content: response.data.alert,
      tag: response.status > 200 && !isWarning ? 'success' : 'info',
      open: true,
      autoClose: response.status !== 400 && !isWarning
    });
  }

  setTimeout(() => state.set({requestInProgress: false}), 0);

  return response;
}, (error) => {
  if (error.response && error.response.data) {
    if (error.response.status >= 500) {
      var s = state.get();
    }
    if (error.response.status === 403 && error.response?.config?.url !== '/sync/') {
      window.location.href = '/notfound';
    }
    // Prevent an alert message on incorrect logins, since the LoginForm component handles those errors.
    if (error.response.data.hasOwnProperty('alert') && error.response.data.alert.indexOf('Incorrect username or password') === -1) {
      state.setAlert({
        content: error.response.status < 500 ? error.response.data.alert : `There was an error contacting the server. ${s.currentUser ? 'Please contact support for further assistance.' : 'You may need to be logged in to complete this request.'}`,
        tag: error.response.status < 500 ? 'warning' : 'danger',
        open: true,
        autoClose: error.response.status !== 400
      });
    } else {
      _.each(error.response.data, (err, key) => {
        if (_.isArray(err) && err.length > 0) {
          let errorMessage = (err.length > 1 ? err.join(', ') : err[0])
            .replace(/null/g, 'empty')
            .replace(/"" is not a valid choice/, 'This field may not be blank');
          state.setAlert({
            content: `${formatUnderscoreField(key, '_', ' ', false, true, true)}: ${errorMessage}`,
            tag: error.response.status < 500 ? 'warning' : 'danger',
            open: true
          });
        }
      });
    }
  } else {
    throw error;
  }

  setTimeout(() => state.set({requestInProgress: false}), 0);

  return Promise.reject(error);
});

ajax.interceptors.request.use((request) => {
  let {path} = state;
  let stateUpdate = {
    requestInProgress: true,
  };

  if (state.currentUser && path[1] !== 'manage' && path[1] !== 'dashboard') {
    stateUpdate.pageHeader = 'Loading';
  }

  state.set(stateUpdate);

  return request;
});

export var defaultProgram = (stateUpdate = null, cb) => {
  let currentProgram;
  let shouldSetState = stateUpdate == null;

  stateUpdate = stateUpdate ? stateUpdate : {};

  ajax.get('/currentprogram/').then((_currentProgram) => {
    currentProgram = _currentProgram.data;
    applyColors(currentProgram);
    return ajax.get('/current/');
  }).then((currentUser) => {
    currentUser = currentUser.data;

    Object.assign(stateUpdate, {
      currentProgram,
      currentUser,
    });

    if (shouldSetState) {
      let preferredCurrency = currentProgram.currency_unit || currentUser.account.currency_unit;
      stateUpdate.preferredCurrency = preferredCurrency;
      setCurrency(preferredCurrency);
      state.set(stateUpdate);
    }

    if (typeof cb === 'function') {
      cb(stateUpdate);
    }
  }).catch((err) => {
    console.log(err);

    stateUpdate = {
      currentProgram,
      currentUser: null
    };

    setCurrency(currentProgram.currency_unit || 'dollars');
    state.set(stateUpdate);

    if (typeof cb === 'function') cb(stateUpdate);
  });
};

export var login = (data, cb) => {
  let clearCurrentUser = state.currentUser != null;

  ajax.post('/login/', data).then((currentUser) => {
    defaultProgram({}, (stateUpdate) => {
      const {currentProgram} = stateUpdate;
      const {currency_unit} = currentUser.data.account;

      if (!currency_unit) {
        setCurrency(currentProgram.currency_unit || currency_unit);
        cb(currentUser.data, currentProgram);
        return;
      }

      if (clearCurrentUser) state.set({currentUser: null});

      cb(currentUser.data, currentProgram);
    });
  }).catch((e) => {
    if (typeof e.response !== 'undefined') {
      cb(null, null, e.response.data.alert);
    } else {
      cb(null, currentProgram);
    }
  });
};

export var logout = (cb) => {
  ajax.post('/logout/').then((/* data */) => {
    defaultProgram({}, (stateUpdate = {}) => {
      state.set(
        Object.assign(stateUpdate, {
          currentUser: null,
          userList: null,
          operatorList: null,
          commentList: null,
          transactionList: null,
          activityList: null,
          cardTransfers: null,
          requestList: null,
          searchList: null,
          tableData: null
        })
      );
      cb();
    });
  }).catch((e) => {
    console.log(e);
  });
};

export var resetPassword = (data, cb) => {
  ajax.post('/reset/', data).then((data) => {
    console.log(data);
    state.set({currentUser: null});
    cb(data);
  }).catch((e) => {
    console.log(e);
  });
};

export var register = (data, cb) => {
  ajax.post('/register/', data).then((data) => {
    console.log(data);
    cb(data);
  }).catch((e) => {
    cb(null);
  });
};

export var location = (data, cb) => {
  ajax.get('/location/', {
    params: data
  }).then((locations) => {
    cb(locations);
  }).catch((e) => {
    console.log(e);
  });
};

export var checkField = (data, cb) => {
  ajax.get('/checkfield/', {
    params: data
  }).then((data) => {
    cb(data);
  }).catch((e) => {
    console.log(e);
  });
};

export const switchAccount = (data, cb) => {
  ajax.post('/switch/', data).then((currentUser) => {
    cb(currentUser.data);
  }).catch((e) => {
    console.log(e);
  });
}

export const sessionContent = (params, cb) => {
  ajax.get('/sessioncontent', {params}).then((res) => {
    cb(null, res.data);
  }).catch((err) => {
    cb(err);
  });
}

export var programList = (data, cb) => {
  ajax.get('/program/', data).then((programs) => {
    cb(_.cloneDeep(programs.data));
  });
};

export var tagList = (data = {params: {}}, cb) => {
  ajax.get('/tags/', data).then((tags) => {
    if (data && typeof cb === 'function') {
      cb(tags.data);
    } else {
      state.set({tagList: map(tags.data.results, (tag) => tag.name)});
    }
  });
};

export var pageList = (data, cb) => {
  ajax.get('/pages/', data).then((pages) => {
    if (typeof cb === 'function') {
      cb(_.cloneDeep(pages.data));
    }
  }).catch((e) => {
    console.log(e);
  });
};

export var mediaList = (data, cb) => {
  ajax.get('/media/', data).then((media) => {
    cb(_.cloneDeep(media.data));
  });
};

export var userList = (data, cb) => {
  ajax.get('/user/', data).then((users) => {
    if (typeof data.params.distributorList === 'undefined') {
      state.set({userList: users.data});
    }
    cb(_.cloneDeep(users.data));
  });
};

export var operatorList = (data, cb) => {
  ajax.get('/operators/', data).then((operators) => {
    cb(_.cloneDeep(operators.data));
  });
};

export var distributorList = (data, cb) => {
  ajax.get('/distributors/', data).then((distributors) => {
    cb(_.cloneDeep(distributors.data));
  });
};

export var brokerList = (data, cb) => {
  ajax.get('/brokers/', data).then((brokers) => {
    cb(_.cloneDeep(brokers.data));
  });
};

export var transactionList = (data, cb) => {
  ajax.get('/transaction/', data).then((transactions) => {
    if (typeof cb === 'function') {
      cb(_.cloneDeep(transactions.data));
    }
  });
};

export var queueList = (data, cb) => {
  ajax.get('/transactionsqueue/', data).then((res) => {
    if (typeof cb === 'function') {
      cb(_.cloneDeep(res.data));
    }
  });
};

export var requestList = (data, cb) => {
  ajax.get('/request/', data).then((requests) => {
    cb(_.cloneDeep(requests.data));
  });
};

export var productList = (data, cb) => {
  ajax.get('/product/', data).then((products) => {
    cb(_.cloneDeep(products.data));
  });
};

export var promotionList = (data, cb) => {
  ajax.get('/promotion/', data).then((promotions) => {
    cb(_.cloneDeep(promotions.data));
  });
};

export var payoutruleList = (data, cb) => {
  ajax.get('/payoutrule/', data).then((payoutrules) => {
    cb(_.cloneDeep(payoutrules.data));
  });
};

export var activityList = (data, cb) => {
  ajax.get('/activity/', data).then((requests) => {
    cb(null, requests.data);
  }).catch((err) => cb(err));
};

export var commentList = (data, cb) => {
  ajax.get('/comment/', data).then((comments) => {
    cb(_.cloneDeep(comments.data));
  });
};

export var searchList = (data, cb) => {
  ajax.get('/search/', data).then((search) => {
    cb(_.cloneDeep(search.data));
  });
};
export var invitationList = (data, cb) => {
  ajax.get('/invitation/', data).then((invitation) => {
    cb(invitation.data);
  });
};

export var programDetail = (id, cb) => {
  ajax.get(`/program/${id}/`).then((program) => {
    cb(program.data);
  });
};

export var mediaDetail = (id, cb) => {
  ajax.get(`/media/${id}/`).then((media) => {
    cb(media.data);
  });
};

export var userDetail = (id, cb) => {
  ajax.get(`/user/${id}/`).then((user) => {
    cb(user.data);
  });
};

export var operatorDetail = (id, cb) => {
  ajax.get(`/operators/${id}/`).then((operator) => {
    cb(operator.data);
  });
};

export var distributorDetail = (id, cb) => {
  ajax.get(`/distributors/${id}/`).then((distributor) => {
    cb(distributor.data);
  });
};

export var brokerDetail = (id, cb) => {
  ajax.get(`/brokers/${id}/`).then((broker) => {
    cb(broker.data);
  });
};

export var transactionDetail = (id, cb) => {
  ajax.get(`/transaction/${id}/`).then((transaction) => {
    cb(transaction.data);
  });
};

export var queueDetail = (id, cb) => {
  ajax.get(`/transactionsqueue/${id}/`).then((res) => {
    cb(res.data);
  });
};

export var requestDetail = (id, cb) => {
  ajax.get(`/request/${id}/`).then((request) => {
    cb(request.data);
  });
};

export var productDetail = (id, cb) => {
  ajax.get(`/product/${id}/`).then((product) => {
    cb(product.data);
  });
};

export var promotionDetail = (id, cb) => {
  ajax.get(`/promotion/${id}/`).then((promotion) => {
    cb(promotion.data);
  });
};

export var payoutruleDetail = (id, cb) => {
  ajax.get(`/payoutrule/${id}/`).then((payoutrule) => {
    cb(payoutrule.data);
  });
};

export var pageDetail = (id, cb) => {
  ajax.get(`/pages/${id}/`).then((page) => {
    cb(null, page.data);
  }).catch((e) => {
    cb(e);
  })
};

export var invitationDetail = (id, cb) => {
  ajax.get(`/invitation/${id}/`).then((invitation) => {
    cb(invitation.data);
  });
};

export var programUpdate = (id, data, cb) => {
  ajax.put(`/program/${id}/`, data).then((program) => {
    cb(program.data);
  });
};

export var tagUpdate = (id, data, cb) => {
  ajax.put(`/tags/${id}/`, data).then((program) => {
    cb(program.data);
  });
};

export var mediaUpdate = (id, data, cb) => {
  ajax.put(`/media/${id}/`, data).then((media) => {
    cb(media.data);
  });
};

export var userUpdate = (id, data, cb) => {
  ajax.put(`/user/${id}/`, data).then((user) => {
    cb(user.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var operatorUpdate = (id, data, cb) => {
  ajax.put(`/operators/${id}/`, data).then((operator) => {
    cb(operator.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var distributorUpdate = (id, data, cb) => {
  ajax.put(`/distributors/${id}/`, data).then((distributor) => {
    cb(distributor.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var brokerUpdate = (id, data, cb) => {
  ajax.put(`/brokers/${id}/`, data).then((broker) => {
    cb(broker.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var transactionUpdate = (id, data, cb) => {
  ajax.put(`/transaction/${id}/`, data).then((transaction) => {
    cb(null, transaction.data);
  }).catch((e) => {
    cb(e);
  });
};

export var queueUpdate = (id, data, cb) => {
  ajax.put(`/transactionsqueue/${id}/`, data).then((res) => {
    cb(null, res.data);
  }).catch((e) => {
    cb(e);
  });
};

export var requestUpdate = (id, data, cb) => {
  ajax.put(`/request/${id}/`, data).then((request) => {
    cb(request.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var productUpdate = (id, data, cb) => {
  ajax.put(`/product/${id}/`, data).then((product) => {
    cb(product.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var promotionUpdate = (id, data, cb) => {
  ajax.put(`/promotion/${id}/`, data).then((promotion) => {
    cb(null, promotion.data);
  }).catch((e) => cb(e));
};

export var payoutruleUpdate = (id, data, cb) => {
  ajax.put(`/payoutrule/${id}/`, data).then((payoutrule) => {
    cb(null, payoutrule.data);
  }).catch((e) => cb(e));
};

export var commentUpdate = (id, data, cb) => {
  ajax.put(`/comment/${id}/`, data).then((comment) => {
    cb(comment.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var pageUpdate = (id, data, cb) => {
  ajax.put(`/pages/${id}/`, data).then((page) => {
    cb(page.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var invitationUpdate = (id, data, cb) => {
  ajax.put(`/invitation/${id}/`, data).then((invitation) => {
    cb(invitation.data);
  });
};

export var programCreate = (data, cb) => {
  ajax.post('/program/', data).then((program) => {
    cb(program.data);
  });
};

export var mediaCreate = (data, cb) => {
  ajax.post('/media/', data).then((media) => {
    cb(media.data);
  });
};

export var transactionCreate = (data, cb) => {
  ajax.post('/transaction/', data).then(() => {
    cb(null);
  }).catch((e) => cb(e));
};

export var requestCreate = (data, cb) => {
  ajax.post('/request/', data).then((request) => {
    cb(request.data);
  });
};

export var productCreate = (data, cb) => {
  ajax.post('/product/', data).then((product) => {
    cb(product.data);
  });
};

export var promotionCreate = (data, cb) => {
  ajax.post('/promotion/', data).then((promotion) => {
    cb(null, promotion.data);
  }).catch((e) => cb(e));
};

export var payoutruleCreate = (data, cb) => {
  ajax.post('/payoutrule/', data).then((payoutrule) => {
    cb(null, payoutrule.data);
  }).catch((e) => cb(e));
};

export var commentCreate = (data, cb) => {
  ajax.post('/comment/', data).then((comment) => {
    cb(comment.data);
  });
};

export var pageCreate = (data, cb) => {
  ajax.post('/pages/', data).then((page) => {
    cb(page.data);
  });
};

export var invitationCreate = (data, cb) => {
  ajax.post('/invitation/', data).then((invitation) => {
    cb(invitation.data);
  });
};

export var tagCreate = (data, cb) => {
  ajax.post('/tags/', data).then((tag) => {
    cb(tag.data);
  });
};

export var mediaDelete = (id, cb) => {
  ajax.delete(`/media/${id}/`).then((media) => {
    cb(media.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var userDelete = (id, cb) => {
  ajax.delete(`/user/${id}/`).then((user) => {
    cb(user.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var operatorDelete = (id, cb) => {
  ajax.delete(`/operators/${id}/`).then((operator) => {
    cb(operator.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var distributorDelete = (id, cb) => {
  ajax.delete(`/distributors/${id}/`).then((distributor) => {
    cb(distributor.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var brokerDelete = (id, cb) => {
  ajax.delete(`/brokers/${id}/`).then((broker) => {
    cb(broker.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var transactionDelete = (id, cb) => {
  ajax.delete(`/transaction/${id}/`).then((transaction) => {
    cb(transaction.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var queueDelete = (id, cb) => {
  ajax.delete(`/transactionsqueue/${id}/`).then((res) => {
    cb(res.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var requestDelete = (id, cb) => {
  ajax.delete(`/request/${id}/`).then((request) => {
    cb(request.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var productDelete = (id, cb) => {
  ajax.delete(`/product/${id}/`).then((product) => {
    cb(product.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var promotionDelete = (id, cb) => {
  ajax.delete(`/promotion/${id}/`).then((promotion) => {
    cb(promotion.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var payoutruleDelete = (id, cb) => {
  ajax.delete(`/payoutrule/${id}/`).then((payoutrule) => {
    cb(payoutrule.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var commentDelete = (id, cb) => {
  ajax.delete(`/comment/${id}/`).then((comment) => {
    cb(comment.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var pageDelete = (id, cb) => {
  ajax.delete(`/pages/${id}/`).then((page) => {
    cb(page.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var invitationDelete = (id, cb) => {
  ajax.delete(`/invitation/${id}/`).then((invitation) => {
    cb(invitation.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var programDelete = (id, cb) => {
  ajax.delete(`/program/${id}/`).then((program) => {
    cb(program.data);
  }).catch((e) => {
    console.log(e);
  });
};

export var tagDelete = (id, cb) => {
  ajax.delete(`/tags/${id}/`).then((tag) => {
    cb(tag.data);
  }).catch((e) => {
    console.log(e);
  });
};

export const getRepList = (p, force = false) => {
  if (!force && p.s.distributorList && p.s.brokerList && p.s.RSMList) {
    return;
  }
  // TODO: Concat this request
  if (p.s.currentUser.permission >= 6) {
    userList({params: {distributorList: true}}, (reps) => {
      userList({params: {brokerList: true}}, (brokers) => {
        userList({params: {RSMList: true}}, (RSMs) => {
          state.set({
            distributorList: _.orderBy(reps.results, 'display_name'),
            brokerList: _.orderBy(brokers.results, 'display_name'),
            RSMList: _.orderBy(RSMs.results, 'display_name'),
          });
        });
      });
    });
  }
};

export const getOperatorList = (p, force = false) => {
  if (!force && (p.s.operatorList)) {
    return;
  }
  if (p.s.currentUser.permission >= 6) {
    userList({params: {operatorList: true}}, (operators) => {
      state.set({operatorList: _.orderBy(operators.results, 'display_name')});
    });
  }
};