import React from 'react';
import _ from 'lodash';
import Favicon from 'react-favicon';

import {state, withState} from '../stores/index';
import {setCurrency} from '../utils/utils';
import outdatedBrowserRework from './outdated-browser';
import {PrimaryNavbar} from './template/navbars';
import {AsyncComponent} from './hoc';
import incentLogo from '../assets/images/incent_logo_monochrome.png';

let AdminSidebar = AsyncComponent({
  loader: () => import(/* webpackChunkName: "sidebar" */ './template/sidebar')
});
let Confirm = AsyncComponent({
  loader: () => import(/* webpackChunkName: "modalConfirm" */ './template/modals/confirm')
});
let Alert = AsyncComponent({
  loader: () => import(/* webpackChunkName: "alert" */ './template/forms/alert')
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleTOSRoute = () => this.props.router.push('/termsofservice/');

  handlePrivacyPolicyRoute = () => this.props.router.push('/privacypolicy/');

  render() {
    let p = this.props;
    return (
      <div
      className="Footer footer-boxed text-muted"
      style={{
        padding: `20px ${p.s.pagePadding}`,
        bottom: '0px',
        position: 'absolute',
        left: p.isAdmin && p.s.width > 768 ? '260px' : '0px'
      }}>
        <div style={{display: 'inline'}}>
          © 2019.{' '}
          <a style={{cursor: 'pointer', paddingLeft: '8px'}} onClick={this.handleTOSRoute}>
            Terms of Service
          </a>
          <span style={{padding: '0px 8px'}}> | </span>
          <a style={{cursor: 'pointer'}} onClick={this.handlePrivacyPolicyRoute}>
            Privacy Policy
          </a>
        </div>
        <div className="incentMark">
          <div className="poweredBy">Powered by</div>
          <img src={incentLogo} className="logo" />
        </div>
      </div>
    );
  }
}

export class PageContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {children, isAdmin, s, router} = this.props;
    let isAdminOrDash = isAdmin || s.path[1] === 'dashboard';
    let childrenWithProps = React.cloneElement(children, this.props);
    let isContent = s.path[1] === 'fundingrequestideas' || s.path[1] === 'promotions';
    return (
      <div className="page-container" style={{padding: isAdminOrDash ? '0px' : '0px 20px 20px 20px'}}>
        <div className="page-content">
          {isAdmin ? <AdminSidebar s={s} router={router} /> : null}
          <div
          className="content-wrapper"
          style={{
              padding: `20px ${isContent && s.width >= 1400 ? '25%' : s.pagePadding} 120px ${isAdmin ? '4px' : s.pagePadding}`,
              marginTop: '0px'
            }}>
            {childrenWithProps}
          </div>
        </div>
      </div>
    );
  }
}

export class Index extends React.Component {
  constructor(props) {
    super(props);
    let originalRouterPush = this.props.router.push;
    this.props.router.push = function() {
      let args = arguments;
      originalRouterPush.call(this, ...args);
      state.set({checkNavigation: true});
    };
    this.connectId = this.props.s.connect({
      path: () => {
        document.body.scrollIntoView();
      },
      currentUser: ({currentUser}) => {
        // Ensure the currency unit is set correctly incase its overriden in transaction views.
        if (currentUser && currentUser.account) {
          setCurrency(this.props.s.currentProgram.currency_unit || currentUser.account.currency_unit);
        }
      },
      pageHeader: ({pageHeader}) => {
        if (this.props.s.currentProgram) {
          let siteTitle = this.props.s.currentProgram.name;
          if (!this.props.s.path[0] && !this.props.s.path[1]) {
            document.title = siteTitle;
          }
          if (this.props.s.path[1] === 'manage') {
            siteTitle = 'Incent Admin';
          }
          if (!this.props.s.path[0] && !this.props.s.path[1]) {
            document.title = siteTitle;
          }

          if (pageHeader === siteTitle) {
            document.title = pageHeader;

            return;
          }

          document.title = `${pageHeader ? pageHeader + ' - ' + siteTitle : ''}`;
        }
      }
    });
  }
  componentDidMount() {
    _.defer(() => {
      outdatedBrowserRework({
        browserSupport: {
          Chrome: 37, // Includes Chrome for mobile devices
          IE: 11,
          Safari: 7,
          'Mobile Safari': 7,
          Firefox: 32
        }
      });
    });
  }
  componentWillUnmount() {
    this.props.s.disconnect(this.connectId);
  }
  render() {
    let p = this.props;
    let notOperator = p.s.currentUser && p.s.currentUser.permission > 1;
    let isAdmin = p.s.path[1] === 'manage' && notOperator;
    if (!p.s.currentProgram || p.s.currentProgram.status === 'inactive') {
      return null;
    }
    let viewClasses =  p.s.path
      .filter(s => s !== '')
      .map(s => `p-${s}`);
    if (viewClasses.length === 0) {
      viewClasses.push('p-');  // Index/root page
    }
    viewClasses = viewClasses.join(' ');
    return (
      <div className={viewClasses}>
        <Favicon url={[p.s.currentProgram.favicon]} animated={false} />
        <div id="outdated" />
        <PrimaryNavbar {...p} />
        {/* (p.s.path[1] === 'manage' && notOperator) || (p.s.path[1] === 'dashboard' && p.s.path[2] === 'profile' && notOperator) ? <SecondaryNavbar {...p} /> : null */}
        <PageContainer isAdmin={isAdmin} {...p}>{p.children}</PageContainer>
        {p.s.alert.show ? <Alert s={p.s} /> : null}
        {p.s.confirm.open ? <Confirm s={p.s} /> : null}
        <Footer s={p.s} router={p.router} isAdmin={isAdmin} />
      </div>
    );
  }
}

Index = withState(Index)