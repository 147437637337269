import React from 'react';
import _ from 'lodash';
import initStore from './state';
import ieCheck from '../utils/iecheck';
import {defaultConfirm} from '../constants';

let uuidV4, find;
export const state = initStore({
  // UI
  alert: {
    list: [],
    default: {
      content: '',
      tag: 'primary', // primary, danger, success, warning, info
      open: false,
      width: '300px',
      statementsPending: false,
      transactionsSaved: false,
      monthlyStatements: false,
      showCloseButton: true,
      autoClose: true,
      time: null,
    },
    show: false
  },
  confirm: _.cloneDeep(defaultConfirm),
  confirms: 0,
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: false,
  pagePadding: '20px',
  // Navigation
  path: window.location.pathname.split('/'),
  pathName: window.location.pathname,
  pageHeader: 'Loading',
  navigationComponent: null,
  title: '',
  sidebar: {
    open: false,
    options: [],
    header: 'Pages'
  },
  registrationComplete: false,
  isIE: ieCheck(),
  checkNavigation: false,
  // AJAX
  urlBase: '',
  loadProgress: true,
  // API
  page: 1,
  pageSize: 200,
  count: 0,
  // Program
  currentProgram: null,
  preferredCurrency: 'dollars',
  programList: null,
  // Media
  mediaList: null,
  showMediaImporter: null,
  selectedMedia: null,
  // Users
  currentUser: null,
  publicUser: null,
  invitationList: null,
  userList: null,
  // Accounts
  operatorList: null,
  distributorList: null,
  brokerList: null,
  RSMList: null,
  commentList: null,
  compactAccounts: null,
  distributorsCount: 0,
  // Transactions
  transactionList: null,
  activityList: null,
  cardTransfers: null,
  // Requests
  requestList: null,
  // Search
  searchList: null,
  // Tables
  view: '',
  isDetailView: false,
  requestInProgress: false,
  tableData: [],
  modelOrder: '-created',
  order: 'Date',
  direction: 'desc',
  selectedRows: [],
  actionOptions: [],
  // Forms
  stateData: null,
  operatorMetadata: null,
  tagList: [],
  preview: null,

  setAlert(obj, cb) {
    if (!uuidV4 || !find) {
      uuidV4 = require('../utils/utils').uuidV4;
      find = require('../utils/utils').find;
    }

    // Allow only one transactions saved confirmation
    if (obj.transactionsSaved
      && find(state.alert.list, (alert) => alert.transactionsSaved != null)) {
      return;
    }

    let newAlert = _.cloneDeep(state.alert.default);
    _.assignIn(obj, {
      time: Date.now(),
      open: true,
      key: uuidV4()
    });

    _.assignIn(newAlert, _.cloneDeep(obj));
    state.alert.list = _.concat([newAlert], state.alert.list);

    state.alert.show = true;
    state.set({alert: _.cloneDeep(state.alert)}, cb);
  },

  saveConfirmAlert(){
    state.setAlert({
      content: 'Your changes were successfully saved.',
      tag: 'info',
      width: '300px',
      showCloseButton: true,
      open: true,
    });
  },

  deleteConfirmAlert(rowID, instance, multi = 0, cb) {
    let format = multi > 1 ? `${multi} ${instance}` : `this ${_.trimEnd(instance, 's')}`

    state.set({
      confirm: {
        content: `Are you sure you want to delete ${format}?`,
        rowID,
        instance,
        open: !state.confirms,
        multi,
        cb
      }
    });
    state.confirms++;
  }
});

// if (process.env.NODE_ENV === 'development') {
//   window.state = state;
// }

export const StateContext = React.createContext('state');

export function withState(Component) {
  return function StateComponent(props) {
    return (
      <StateContext.Consumer>
        {state => <Component {...props} s={state} />}
      </StateContext.Consumer>
    );
  };
}