import $ from 'jquery';
import _ from 'lodash';
import sizeOf from 'object-sizeof';
import moment from 'moment';
import tc from 'tinycolor2';

import {imageExtensions, permissionLabels, userLabelsShort} from '../constants';
import {mediaCreate} from '../services';
import {state} from '../stores';

export const each = (obj, cb) => {
  if (Array.isArray(obj)) {
    for (let i = 0, len = obj.length; i < len; i++) {
      let returnValue = cb(obj[i], i);
      if (returnValue === false) {
        return;
      } else if (returnValue === null) {
        break;
      } else if (returnValue === true) {
        continue;
      }
    }
  } else if (typeof obj === 'object') {
    let keys = Object.keys(obj);
    for (let i = 0, len = keys.length; i < len; i++) {
      cb(obj[keys[i]], keys[i]);
    }
  }
};

export const findIndex = function(arr, cb) {
  for (let i = 0, len = arr.length; i < len; i++) {
    if (cb(arr[i], i, arr)) {
      return i;
    }
  }
  return -1;
}

export const find = function(arr, cb) {
  for (let i = 0, len = arr.length; i < len; i++) {
    if (cb(arr[i], i, arr)) {
      return arr[i];
    }
  }
  return null;
}

export const filter = function (arr, cb) {
  let result = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    if (cb(arr[i], i, arr)) {
      result.push(arr[i]);
    }
  }
  return result;
};

export const map = function (arr, fn) {
  if (arr == null) {
    return [];
  }

  let len = arr.length;
  let out = Array(len);

  for (let i = 0; i < len; i++) {
    out[i] = fn(arr[i], i, arr);
  }

  return out;
}

export const includes = function (arr, val, index) {
  for (let i = 0 | index; i < arr.length; i++) {
    if (arr[i] === val) {
      return true;
    }
  }
  return false;
}

export const merge = function() {
  let [result, ...extenders] = Array.from(arguments);
  for (let i = 0, len = extenders.length; i < len; i++) {
    let keys = Object.keys(extenders[i]);
    for (let z = 0, len = keys.length; z < len; z++) {
      result[keys[z]] = extenders[i][keys[z]]
    }
  }
  return result;
}

export const whichToShow = function({outerHeight, itemHeight, scrollTop, columns}) {
  let start = Math.floor(scrollTop / itemHeight);
  let heightOffset = scrollTop % itemHeight;
  let length = Math.ceil((outerHeight + heightOffset) / itemHeight) * columns;

  return {
    start: start,
    length: length,
  }
}

export const tryFn = function(fn, errCb) {
  try {
    return fn();
  } catch (e) {
    if (typeof errCb === 'function') {
      errCb(e);
    }
  }
};

export const upperFirst = function(string) {
  if (!string) string = '';
  if (typeof string !== 'string') string = string.toString();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const unref = function(object) {
  setTimeout(() => {
    let keys = Object.keys(object);
    for (let i = 0; i < keys.length; i++) {
      object[keys[i]] = null;
    }
  }, 0);
};


export const passwordStrengthCheck = (password) => {
  let score = 0;

  // Has one uppercase chars
  if (password.search(/(?=.*[A-Z])/) !== -1) {
    score += 20;
  }

  // Has three lowercase chars
  if (password.search(/(?=.*[a-z].*[a-z].*[a-z])/) !== -1) {
    score += 20;
  }

  // Has one digits
  if (password.search(/(?=.*[0-9].)/) !== -1) {
    score += 20;
  }

  // Has one special char
  if (password.search(/(?=.*[!@#$&*])/) !== -1) {
    score += 10;
  }

  // Has six chars
  if (password.length >= 6) {
    score += 30;
  }
  console.log(score);
  return score;
};

export const createDataUri = (e, cb) => {
  const files = typeof e.target.files !== 'undefined' ? e.target.files : e.dataTransfer.files;
  console.log('Files: ', e.target.files);

  const parseImage = (readerResult, extension, name, shouldCallback) => {
    let sourceImage = new Image();
    let isJPEG = extension.indexOf('jpeg') > -1;
    sourceImage.onload = ()=> {
      let imgWidth = sourceImage.width;
      let imgHeight = sourceImage.height;
      let canvas = document.createElement('canvas');
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      canvas.getContext('2d').drawImage(sourceImage, 0, 0, imgWidth, imgHeight);
      let newDataUri = imgWidth >= 900 || imgHeight >= 900 || isJPEG ? canvas.toDataURL('image/jpeg', 0.90) : canvas.toDataURL('image/png');
      if (newDataUri) {
        cb(newDataUri, name, shouldCallback);
      }
    };
    sourceImage.src = readerResult;
  }

  const readFile = (i, shouldCallback) => {
    let reader = new FileReader();
    reader.onload = (e)=> {
      let extension = reader.result.split('/')[1].split(';')[0];
      let name = files[i].name;
      let possibleExtension = name.indexOf('.') > -1 ? _.last(name.split('.')) : null;
      if (possibleExtension.length === 3
        || possibleExtension.length === 4) {
        name = `${name.split(possibleExtension)[0]}${extension}`;
      } else if (!possibleExtension) {
        name = `${name}${extension}`;
      }
      console.log('FILE: ', possibleExtension, extension, reader, e.target || e.dataTransfer);
      if (extension === 'pdf') {
        cb(reader.result, name, shouldCallback);
      } else {
        parseImage(reader.result, extension, name, shouldCallback);
      }
    };
    reader.readAsDataURL(files[i]);
  };
  for (let i = 0, len = files.length; i < len; i++) {
    readFile(i, i === len - 1 || len === 1);
  }
};

export const uploadMedia = (e, cb) => {
  let urls = [];
  createDataUri(e, (dataUri, name, shouldCallback) => {
    mediaCreate({
      name: name,
      data: dataUri,
      size: sizeOf(dataUri),
    }, (res) => {
      urls.push(res.data);
      if (shouldCallback) {
        cb(urls);
      }
    });
  });
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) {
    return '0 Byte';
  }
  let k = 1000;
  let dm = decimals + 1 || 3;
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(dm) + ' ' + sizes[i];
};

export const truncateMiddle = (str, frontLen, backLen, truncateStr) => {
  if (str === null) {
    return '';
  }
  let strLen = str.length;
  frontLen = ~~frontLen;
  backLen = ~~backLen;
  truncateStr = truncateStr || '&hellip;';
  if (frontLen === 0 && backLen === 0 || frontLen >= strLen || backLen >= strLen || (frontLen + backLen) >= strLen) {
    return str;
  } else if (backLen === 0) {
    return str.slice(0, frontLen) + truncateStr;
  } else {
    return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
  }
}

export const formatUnderscoreField = (field='', _from='_', to=' ', lowerCase=false, noCase=false, upperFirst = false) => {
  if (field.length === 0) {
    return '';
  }

  let fieldArray = [];
  if (field.indexOf(_from) > -1) {
    fieldArray = field.split(_from);
  } else {
    fieldArray = [field]
  }
  let fieldParts = [];
  _.each(fieldArray, (fieldPart) => {
    let string = (fieldPart.length > 2 || fieldArray.length === 1) && !noCase ? _.upperFirst(fieldPart) : fieldPart;
    fieldParts.push(string);
  });
  if (fieldParts.length > 0) {
    field = fieldParts.join(to).trim();
  }

  field = lowerCase ? field.toLowerCase() : upperFirst ?  _.upperFirst(field) : field;
  return field;
};

export const moveArray = (array, fromIndex, toIndex) => {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
};
window.$ = $;

export const applyColors = (program, isPreview = false) => {
  let theme = program.data.theme;

  if (!document.getElementById('__theme__')) {
    $('<style id="__theme__" />').appendTo('head');
  }

  const dashboardShortcutsLink = tc.mostReadable(theme.dashboardShortcutsBg, [
    theme.sNavLink,
    theme.navLink,
    theme.navLinkHover,
  ]);

  document.getElementById('__theme__').innerHTML = `
    .nav-t {
      background-color: ${theme.navBg}${isPreview ? ' !important' : ''};
      border-color: ${theme.navBg}${isPreview ? ' !important' : ''};
    }
    .modal-header, .modal-header.bg-blue-800 {
      background-color: ${theme.navBg};
      border-color: ${theme.navBg};
    }
    #app > div > div.page-container > div > div > div > div.modal.fade.in > div.modal-dialog.modal-full > div > div.modal-header.bg-blue-800,
    #app > div > div.page-container > div > div.content-wrapper > div > div:nth-child(2) > div > div > div.modal.fade.in > div.modal-dialog.modal-full > div > div.modal-header.bg-blue-800 {
      background-color: ${theme.navBg};
      border-color: ${theme.navBg};
    }
    .border-top-teal {
      border-top-color: ${theme.navBg} !important;
    }
    .navbar-inverse .navbar-nav > li > a,
    .navbar-header .navbar-nav > li > a {
      color: ${theme.navLink};
    }
    .navbar-inverse .navbar-nav > li > a:hover,
    .navbar-header .navbar-nav > li > a:hover,
    #navbar-mobile > ul.nav.navbar-nav.navbar-right > li.dropdown.dropdown-user.open > a {
      color: ${theme.navLinkHover};
    }
    #app > div > div.page-container > div > div > div > div.content-group > div.adminNav, .sidebar-content {
      background-color: ${theme.sNavBg} !important;
      color: ${theme.sNavLink} !important;
    }
    #app > div > div.page-container > div > div.sidebar.sidebar-main.sidebar-default > div > div > div > ul > li > ul > li > a,
    #app > div > div.page-container > div > div.sidebar.sidebar-main.sidebar-default > div > div > div > ul > li > a {
      color: ${theme.sNavLink};
    }
    .sidebar-default .navigation li > a:hover, .sidebar-default .navigation li > a:focus {
      background-color: ${theme.sNavItemBg} !important;
    }
    #app > div > div.page-container > div > div.sidebar.sidebar-main.sidebar-default > div > div > div > ul > li.active {
      background-color: ${theme.sNavActiveSubBg};
    }
    #app > div > div.page-container > div > div.sidebar.sidebar-main.sidebar-default > div > div > div > ul > li.active > a {
      background-color: ${theme.sNavActiveBg};
    }
    #app > div > div.page-container > div > div.sidebar.sidebar-main.sidebar-default > div > div > div > ul > li.active > ul > li > a:hover {
      background-color: ${theme.sNavActiveSubHoverBg} !important;
    }
    #btn,
    .btn-labeled.btn-labeled-right,
    #app > div > div.page-container > div > div.content-wrapper > div > div > div > div > div > div > div > div.datatable-footer > div.dataTables_paginate.paging_simple_numbers > span > a:not(.current),
    #app > div > div.page-container > div > div > div > div > div:nth-child(3) > div.panel-body > div.text-right > button.btn.bg-blue-800.btn-labeled.btn-labeled-right.ml-10.legitRipple,
    #app > div > div.page-container > div > div > div > div > div > div.panel-body > div > div.panel.panel-flat.registration-form > div > div > button.btn.bg-blue-800.btn-labeled.btn-labeled-right.ml-10.legitRipple,
    .select2-results__option--highlighted {
      background-color: ${theme.btnBg} !important;
      color: ${theme.btnText} !important;
    }
    .dataTables_paginate .paginate_button.current, .dataTables_paginate .paginate_button.current:hover, .dataTables_paginate .paginate_button.current:focus {
      background-color: ${theme.btnBg};
      color: ${theme.btnText};
    }
    .bg-blue-800 {
      background-color: ${theme.btnBg};
      color: ${theme.btnText};
    }
    .outline-themed {
      color: ${theme.btnBg};
    }
    .tokenfield .token:not([class*=bg-]):hover,
    .tokenfield .token:not([class*=bg-]):focus {
      background-color: ${theme.btnBg} !important;
      color: ${theme.btnText} !important;
    }
    #cta,
    .dataTables_paginate .paginate_button.current,
    .dataTables_paginate .paginate_button.current:hover,
    .dataTables_paginate .paginate_button.current:focus {
      background-color: ${theme.ctaBtnBg} !important;
      color: ${theme.ctaBtnText} !important;
    }
    .UserBox {
      background-color: ${theme.dashboardUserBg} !important;
      color: ${theme.dashboardUserText} !important;
    }
    .UserBox > div > div > div:nth-child(3) > a {
      color: ${tc.mostReadable(theme.dashboardUserBg, [
        theme.sNavLink,
        theme.navLink,
        theme.navLinkHover,
      ]).toRgbString()} !important;
    }
    .dashboardShortcuts,
    .dashboardShortcuts > div.navbar-collapse.collapse {
      background-color: ${theme.dashboardShortcutsBg} !important;
    }
    .dashboardShortcuts > div.navbar-header > .navbar-brand {
      color: ${theme.dashboardShortcutsText} !important;
    }
    .dashboardShortcuts > div.navbar-collapse.collapse > ul > li > a {
      color: ${dashboardShortcutsLink.toRgbString()} !important;
    }
    .dashboardShortcuts > div.navbar-collapse.collapse > ul > li > a:hover {
      color: ${dashboardShortcutsLink.setAlpha(0.9).toRgbString()} !important;
    }
    ${program.additional_css}
  `;
};

export const getKeysFromCollection = function(arr) {
  let keys = [];
  let amountKeys = [];
  let accountKeys = [];
  let _otherKeys = [];
  let validAccountKeys = ['Operator', 'Distributor', 'Broker'];
  each(arr, function(item) {
    each(item, function(val, key) {
      if (key.indexOf('Amount') > -1 && amountKeys.indexOf(key) === -1) {
        amountKeys.push(key);
      } else if (validAccountKeys.indexOf(key) > -1 && accountKeys.indexOf(key) === -1) {
        accountKeys.push(key);
      } else if (amountKeys.indexOf(key) === -1
        && accountKeys.indexOf(key) === -1
        && keys.indexOf(key) === -1) {
        keys.push(key);
      }
    })
  });

  let operatorAccount = accountKeys.indexOf('Operator');
  let distributorAccount = accountKeys.indexOf('Distributor');
  let brokerAccount = accountKeys.indexOf('Broker');
  let operatorAmount = amountKeys.indexOf('Amount');
  let distributorAmount = amountKeys.indexOf('DSR Amount');
  let brokerAmount = amountKeys.indexOf('BSR Amount');

  if (operatorAccount > -1) {
    _otherKeys.push('Operator');
  }
  if (operatorAmount > -1) {
    _otherKeys.push('Amount');
  }
  if (distributorAccount > -1) {
    _otherKeys.push('Distributor');
  }
  if (distributorAmount > -1) {
    _otherKeys.push('DSR Amount');
  }
  if (brokerAccount > -1) {
    _otherKeys.push('Broker');
  }
  if (brokerAmount > -1) {
    _otherKeys.push('BSR Amount');
  }

  keys = keys.concat(_otherKeys);

  let statusIndex = keys.indexOf('Status');
  if (statusIndex > -1) {
    keys.splice(statusIndex, 1);
    keys.push('Status');
  }
  return keys;
}

export const formatTransactionRow = (obj) => {
  let {path, currentUser} = state;
  let date = obj.invoice_date;

  _.merge(obj, {
    Date: new Date(date).getTime(),
    Description: {
      title: obj.title,
      description: obj.description
    },
    Product: obj.product ? obj.product.name : '',
    Quantity: obj.total_quantity,
    ProductUUID: obj.product ? obj.product.id : null,
    Promotion: obj.promotions[0] ? obj.promotions[0].name : ''
  });

  let selectedKeys = ['Date', 'Description', 'Product', 'Quantity'];

  if (path[1] === 'manage' && currentUser.permission > 1) {
    if (obj.operator_account) {
      obj.Operator = obj.operator_account.name;
      obj.OperatorUUID = obj.operator_account.id;
      selectedKeys.push('Operator', 'OperatorUUID');
    }
    if (obj.distributor_account) {
      obj.Distributor = obj.distributor_account.name;
      obj.DistributorUUID = obj.distributor_account.id;
      selectedKeys.push('Distributor', 'DistributorUUID');
    }
    if (obj.broker_account) {
      obj.Broker = obj.broker_account.name;
      obj.BrokerUUID = obj.broker_account.id;
      selectedKeys.push('Broker', 'BrokerUUID');
    }
  }

  let selectedKeys2 = ['status', 'Actions', 'id', 'CompanyUUID', 'ProductUUID', 'Promotion'];

  if (obj.amount) {
    obj.Amount = parseFloat(obj.amount);
    selectedKeys2 = ['Amount'].concat(selectedKeys2);
  }

  if (obj.distributor_amount) {
    obj['DSR Amount'] = parseFloat(obj.distributor_amount);
    selectedKeys2 = ['DSR Amount'].concat(selectedKeys2);
  }

  if (obj.broker_amount) {
    obj['BSR Amount'] = parseFloat(obj.broker_amount);
    selectedKeys2 = ['BSR Amount'].concat(selectedKeys2);
  }

  selectedKeys = selectedKeys.concat(selectedKeys2)
  if (path[1] === 'manage' && currentUser.permission > 5) {
    obj.Actions = [];

    if (obj.status !== 'processing') obj.Actions.push({label: 'Mark As Processing'});
    if (obj.status !== 'pending') obj.Actions.push({label: 'Mark As Pending Payment'});
    if (obj.status !== 'released') obj.Actions.push({label: 'Mark As Released'});

    obj.Actions = obj.Actions.concat([
      {label: 'Edit Description'},
      {label: 'Delete'}
    ]);
  }

  let row = _.mapKeys(_.pick(obj, selectedKeys), (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'name') {
      key = 'Account';
    }
    return _.upperFirst(key);
  });

  return row;
};

export const formatQueueRow = (obj) => {
  _.merge(obj, {
    Date: new Date(obj.created).getTime(),
    Rows: obj.data.length,
    Processed: obj.imported.length || 0,
    Actions: [
      {label: 'Delete'}
    ]
  });

  if (obj.status === 'failed') {
    obj.Actions = [{label: 'Mark As Queued'}].concat(obj.Actions);
  }

  let row = _.pick(obj, 'Date', 'Rows', 'Processed', 'status', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });

  return row;
};

export const formatRequestRow = (obj) => {
  let {currentUser} = state;

  _.merge(obj, {
    Company: obj.operator_account ? obj.operator_account.name : 'Unassigned',
    Amount: parseFloat(obj.amount),
    need_by_date: new Date(obj.need_by_date).getTime(),
    Date: new Date(obj.created).getTime()
  });
  if (currentUser.permission > 5) {
    obj.Actions = [
      {label: obj.status === 'approved' ? 'Decline' : obj.status === 'new' ? 'Approve' : obj.status === 'declined' ? 'Approve' : 'Reopen'}
    ];
    if (obj.status === 'new') {
      obj.Actions.push({
        label: 'Decline'
      });
    } else if (obj.status === 'approved') {
      obj.Actions.push({
        label: 'Mark As Released'
      });
    }
    obj.Actions.push({
      label: 'Delete'
    });
  }

  let row = _.pick(obj, 'Company', 'Amount', 'need_by_date', 'status', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'need_by_date') {
      key = 'Need By Date';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatOperatorRow = (obj) => {
  let {currentUser} = state;

  _.merge(obj, {
    'Primary Contact': obj.owner ? obj.owner.display_name : 'Unassigned',
    OwnerPermission: obj.owner ? obj.owner.permission : 0,
    UserUUID: obj.owner ? obj.owner.id : null,
  });
  if (currentUser.permission > 5) {
    obj.Actions = [
      {label: obj.status === 'active' ? 'Suspend' : obj.status === 'new' ? 'Approve' : 'Activate'},
      {label: 'Change Primary Contact'},
      {label: 'Delete'}
    ];
  }
  let row = _.pick(obj, 'name', 'Primary Contact', 'status', 'Actions', 'id', 'UserUUID', 'OwnerPermission');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'name') {
      key = 'Company';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatDistributorRow = (obj) => {
  let {currentUser} = state;

  _.merge(obj, {
    'Primary Contact': obj.owner ? obj.owner.display_name : 'Unassigned',
    OwnerPermission: obj.owner ? obj.owner.permission : null,
    UserUUID: obj.owner ? obj.owner.id : null,
  });
  if (currentUser.permission > 5) {
    obj.Actions = [
      {label: obj.status === 'active' ? 'Suspend' : obj.status === 'new' ? 'Approve' : 'Activate'},
      {label: 'Change Manager'},
      {label: 'Delete'}
    ];
  }
  let row = _.pick(obj, 'name', 'Primary Contact', 'status', 'Actions', 'id', 'UserUUID', 'OwnerPermission');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'name') {
      key = 'Company';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatTagRow = (obj) => {
  _.merge(obj, {
    Date: new Date(obj.created).getTime(),
    Name: obj.name,
    Actions: [
      {label: 'Edit Name'},
      {label: 'Delete'}
    ]
  });
  let row = _.pick(obj, 'Name', 'Date', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatProductRow = (obj) => {
  let {currentUser} = state;

  if (currentUser.permission > 5) {
    obj.Actions = [
      {label: 'Delete'}
    ];
  }
  _.assignIn(obj, {
    brand: obj.client_brand
  });
  let row = _.pick(obj, 'name', 'brand', 'upc_code', 'manufacturer_code', 'manufacturer_id', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'upc_code') {
      key = 'UPC';
    }
    if (key === 'manufacturer_code') {
      key = 'Manufacturer Code';
    }
    if (key === 'manufacturer_id') {
      key = 'Manufacturer ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatPayoutRuleRow = (obj) => {
  let {currentUser} = state;

  _.assignIn(obj, {
    Date: obj.created,
  });

  if (currentUser.permission > 4) {
    obj.Actions = [
      {label: 'Delete'}
    ];
  }

  let row = _.pick(obj, 'name', 'Date', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatPromotionRow = (obj) => {
  let {currentUser} = state;

  if (currentUser.permission > 4) {
    obj.Actions = [
      {label: 'Delete'}
    ];
  }

  obj.Rules = obj.rules.length;
  let row = _.pick(obj, 'name', 'start', 'end', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

// Route only viewed by administrators
export const formatUserRow = (obj) => {
  let {currentUser} = state;

  let ownerLabel = `Make ${obj.permission > 1 ? 'Owner' : 'Primary'}`;
  _.assignIn(obj, {
    created: new Date(obj.created).getTime(),
    'Active Account': obj.account && obj.account.id ? obj.account.name : 'Unassigned',
    Name: obj.is_primary ? `${obj.display_name} (Primary)` : obj.display_name,
    CompanyUUID: obj.account && obj.account.id ? obj.account.id : null,
    CompanyType: obj.account && obj.account.type ? `${obj.account.type.split('Account')[0].toLowerCase()}s` : null,
    Permission: permissionLabels[obj.permission],
    Actions: [
      {label: obj.status === 'active' ? 'Suspend' : obj.status === 'new' ? 'Approve' : 'Activate'},
      {label: ownerLabel},
      {label: 'Change Account'},
      {label: 'Change Permission'},
      {label: 'Delete'}
    ]
  });
  let selectedKeys = ['Name', 'Active Account', 'created', 'status', 'Permission', 'Actions', 'id', 'CompanyUUID', 'CompanyType'];
  let isPrimaryOrNew = !obj.account || obj.account.name === 'Unassigned' || obj.is_primary;

  if (currentUser.permission < 6) {
    delete obj.Actions;
    _.pullAt(selectedKeys, selectedKeys.indexOf('Actions'));
  } else {
    if (isPrimaryOrNew) {
      _.pullAt(obj.Actions, findIndex(obj.Actions, (action) => action.label === ownerLabel));
    }
    if (obj.permission > 3) {
      _.pullAt(obj.Actions, findIndex(obj.Actions, (action) => action.label === 'Change Account'));
    }

    selectedKeys = ['Name', 'Email'].concat(selectedKeys.slice(1));
    obj.Email = obj.email;
  }

  let row = _.pick(obj, ...selectedKeys);
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    if (key === 'created') {
      key = 'Signup Date';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatProgramRow = (obj) => {
  _.merge(obj, {
    Date: new Date(obj.created).getTime(),
    Name: obj.name,
    Actions: [
      {label: obj.status === 'active' ? 'Suspend' : obj.status === 'new' ? 'Approve' : 'Activate'},
      {label: 'Delete'}
    ]
  });
  let row = _.pick(obj, 'Name', 'Date', 'status', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatMediaRow = (obj) => {
  let type = _.last(obj.data.split('.'));
  _.merge(obj, {
    Preview: obj.data,
    Name: truncateMiddle(obj.data.split('/media/')[1], 14, 14, '...'),
    Date: new Date(obj.created).getTime(),
    Size: obj.size,
    Type: type.toUpperCase(),
    Actions: [
      {label: 'Copy URL to Clipboard'},
      {label: 'Download'},
      {label: 'Delete'}
    ]
  });
  let row = _.pick(obj, 'Preview', 'Name', 'Date', 'Size', 'Type', 'Actions', 'id');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatPageRow = (obj) => {
  let permissions = [];
  each(obj.user_permissions, (permission) => {
    let shouldReturn = false;
    tryFn(() => permission = parseInt(permission), () => shouldReturn = true);
    if (shouldReturn) return;
    permissions.push(userLabelsShort[permission]);
  });
  _.merge(obj, {
    Date: new Date(obj.created).getTime(),
    Name: obj.label_internal,
    Permissions: permissions.join(', '),
    Enabled: obj.enabled,
    Navigation: obj.show_in_navigation,
    route: `/content/${obj.slug}`,
    Actions: [
      {label: 'Copy URL to Clipboard'},
      {label: 'Delete'}
    ],
    'Page Type': formatUnderscoreField(obj.page_type),
  });
  let row = _.pick(obj, 'Name', 'Page Type', 'Permissions', 'Enabled', 'Navigation', 'Date', 'Actions', 'id', 'route');
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const formatInvitationRow = (obj) => {
  let {path, currentUser} = state;

  let Company = 'Unassigned';
  let CompanyUUID = '';

  if (obj.broker_account) {
    Company = obj.broker_account.name;
    CompanyUUID = obj.broker_account.id;
  } else if (obj.distributor_account) {
    Company = obj.distributor_account.name;
    CompanyUUID = obj.distributor_account.id;
  } else if (obj.operator_account) {
    Company = obj.operator_account.name;
    CompanyUUID = obj.operator_account.id;
  }

  _.merge(obj, {
    Company,
    CompanyUUID,
    'From User': obj.from_user ? obj.from_user.display_name : 'N/A',
    'To Email': obj.to_email,
    Type: permissionLabels[obj.to_user_type],
    Date: new Date(obj.created).getTime(),
  });

  let selectedKeys = ['Company', 'From User', 'To Email', 'accepted', 'Date', 'Actions', 'id', 'CompanyUUID']

  if (currentUser.permission > 5 && path[1] === 'manage') {
    obj.Actions = [{
      label: 'Delete'
    }];
  } else {
    _.pullAt(selectedKeys, selectedKeys.indexOf('Actions'));
  }

  let row = _.pick(obj, ...selectedKeys);
  row = _.mapKeys(row, (val, key) => {
    if (key === 'id') {
      key = 'ID';
    }
    return _.upperFirst(key);
  });
  return row;
};

export const getRowFormatter = (viewSingular) => {
  if (viewSingular === 'payoutrule') {
    viewSingular = 'payoutRule';
  }
  return Function(
    'formatTransactionRow',
    'formatQueueRow',
    'formatOperatorRow',
    'formatDistributorRow',
    'formatTagRow',
    'formatRequestRow',
    'formatProductRow',
    'formatPayoutRuleRow',
    'formatPromotionRow',
    'formatUserRow',
    'formatProgramRow',
    'formatMediaRow',
    'formatPageRow',
    'formatInvitationRow',
    `return format${_.upperFirst(viewSingular === 'broker' ? 'distributor' : viewSingular)}Row`).call(
    this,
    formatTransactionRow,
    formatQueueRow,
    formatOperatorRow,
    formatDistributorRow,
    formatTagRow,
    formatRequestRow,
    formatProductRow,
    formatPayoutRuleRow,
    formatPromotionRow,
    formatUserRow,
    formatProgramRow,
    formatMediaRow,
    formatPageRow,
    formatInvitationRow,
  );
};

export const buildCMSSidebar = (currentProgram, sidebar) => {
  let options = [];

  if (currentProgram.folders) {
    each(currentProgram.folders, (folder) => {
      options.push({
        label: folder.name,
        route: `/manage/content/folder/${folder.id}/`,
        icon: 'folder2',
        iconActive: 'folder-open'
      });
    });
  }

  _.assignIn(sidebar, {
    open: true,
    header: null,
    options: _.orderBy(options, 'label')
  });
  state.set({sidebar});
};

export const buildProgramSidebar = (path, sidebar) => {
  let startURL = `/manage/programs/${path[3]}`;
  _.assignIn(sidebar, {
    open: true,
    header: null,
    options: [
      {label: 'Profile', route: `${startURL}/profile/`, subOptions: null},
      {label: 'Colors', route: `${startURL}/colors/`, subOptions: null},
      {label: 'Images', route: `${startURL}/images/`, subOptions: null},
      {label: 'Transactions', route: `${startURL}/transactions/`, subOptions: null},
    ]
  });
  state.set({sidebar});
}

export const findContentByUrl = (p) => {
  let refProgramPage = find(p.s.currentProgram.pages, page => page.slug === p.s.path[2]);
  if (refProgramPage) {
    return refProgramPage;
  }
  return null;
};

export const setCurrency = (currency) => {
  let isDollars = currency === 'dollars';

  Number.prototype.format = function(n, x, override) {
    if (override) {
      isDollars = override;
    }
    let d = isDollars ? '$' : '';
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : d) + ')';
    let dollarOutput = this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    if (dollarOutput.indexOf('-') !== -1) {
      dollarOutput = dollarOutput.split('-')[1];
      dollarOutput = `-${d}${dollarOutput}`;
    } else {
      dollarOutput = `${d}${dollarOutput}`;
    }
    if (!isDollars) {
      dollarOutput = `${dollarOutput.replace(/,/g, '').split('.')[0]} ${_.upperFirst(currency)}`;
    }
    return dollarOutput;
  };
};

export const formatAmount = function(amount = 0, n = 0, currency = 'dollars') {
  let isDollars = currency === 'dollars';

  let d = isDollars ? '$' : '';
  let re = '\\d(?=(\\d{' + (3) + '})+' + (n > 0 ? '\\.' : d) + ')';
  let dollarOutput = amount.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');

  if (dollarOutput.indexOf('-') !== -1) {
    dollarOutput = dollarOutput.split('-')[1];
    dollarOutput = `-${d}${dollarOutput}`;
  } else {
    dollarOutput = `${d}${dollarOutput}`;
  }

  if (!isDollars) {
    dollarOutput = `${dollarOutput.replace(/,/g, '').split('.')[0]} ${_.upperFirst(currency)}`;
  }

  return dollarOutput;
}

export const isFile = (src) => {
  let extension = _.last(src.split('.'));
  return imageExtensions.indexOf(extension) === -1;
};

export const validateEmail = (value) => {
  return value.search(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i) > -1;
};

export const validateBarcode = function(barcode) {
  // check length
  if (barcode.length < 8 || barcode.length > 18 ||
    (barcode.length !== 8 && barcode.length !== 12 &&
    barcode.length !== 13 && barcode.length !== 14 &&
    barcode.length !== 18)) {
    return false;
  }

  var lastDigit = Number(barcode.substring(barcode.length - 1));
  var checkSum = 0;
  if (isNaN(lastDigit)) {
    return false;
  } // not a valid upc/ean

  var arr = barcode.substring(0, barcode.length - 1).split('').reverse();
  var oddTotal = 0, evenTotal = 0;

  for (var i = 0; i < arr.length; i++) {
    if (isNaN(arr[i])) {
      return false;
    } // can't be a valid upc/ean we're checking for

    if (i % 2 === 0) {
      oddTotal += Number(arr[i]) * 3;
    } else {
      evenTotal += Number(arr[i]);
    }
  }
  checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;

  // true if they are equal
  return checkSum === lastDigit;
}

export const onElementHeightChange = function(el, callback) {
  let lastHeight = el.clientHeight;
  let newHeight;
  let timer;
  (function run() {
    newHeight = el.clientHeight;
    if (lastHeight !== newHeight) {
      let shouldContinue = callback();
      if (!shouldContinue) {
        return;
      }
    }
    lastHeight = newHeight;

    if (el.onElementHeightChangeTimer) {
      clearTimeout(timer);
    }

    timer = setTimeout(run, 1000);
  })();
}

export const isMobile = function() {
  let isMobile = false;
  // device detection
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
  }
  return window.innerWidth < 769 && isMobile;
}

export const uuidV4 = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const pushData = function(obj) {
  if (!window._loq) {
    return;
  }
  window._loq.push([obj]);
}

export const getPercent = (v1, v2) => ((v1 / v2) * 100).toFixed(0);

export const formatExportDate = (date) => {
  return moment(date).format('M/D/YYYY');
}

export const averageNumberArray = (times = []) => {
  let sum = 0;
  each(times, (time) => {
    sum += time;
  });
  return sum / times.length;
};
