import React from 'react';
import {Router, Route, IndexRoute, browserHistory} from 'react-router';

import App from './app';
import {AsyncComponent} from './components/hoc';

import moment from 'moment';
window.moment = moment;

import {state} from './stores/index';
import {determineView} from './utils/routes';

browserHistory.listen(function(_location) {
  let {currentUser} = state;
  let path = _location.pathname.split('/');

  console.log('LOCATION: ', _location);

  state.set({
    path: path,
    pathName: _location.pathname,
    ...determineView(path, currentUser),
  });
});

let Home = AsyncComponent({
  loader: () => import(/* webpackChunkName: "home" */ './components/home/index')
});

let Content = AsyncComponent({
  loader: () => import(/* webpackChunkName: "home" */ './components/home/index')
}); // TODO: Consider pulling Content view logic into it's own Component to simplify Home state mgmt.

let AdminDashboard = AsyncComponent({
  loader: () => import(/* webpackChunkName: "dashboard" */ './components/admin/index')
});

let Error404 = AsyncComponent({
  loader: () => import(/* webpackChunkName: "404" */ './components/404.js')
});

class Root extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Route path="/" component={App}>
          <IndexRoute component={Home} />
          <Route path="/termsofservice/" component={Home} />
          <Route path="/privacypolicy/" component={Home} />
          <Route path="/signup/" component={Home} />
          <Route path="/signup/operator/" component={Home} />
          <Route path="/signup/broker/" component={Home} />
          <Route path="/signup/distributor/" component={Home} />
          <Route path="/signup/pending/" component={Home} />
          <Route path="/signup/invite/" component={Home} />
          <Route path="/invite/" component={Home} />
          <Route path="/invite/send/" component={Home} />
          <Route path="/invite/:id" component={Home} />
          <Route path="/invite/:type/:user" component={Home} />
          <Route path="/promotions/" component={Home} />
          <Route path="/fundingrequestideas/" component={Home} />
          <Route path="/reset/" component={Home} />
          <Route path="/reset/:id" component={Home} />
          <Route path="/content/:id" component={Content} />
          <Route path="/dashboard/" component={Home} />
          <Route path="/dashboard/profile/:id" component={AdminDashboard} />
          <Route path="/dashboard/transfers/" component={AdminDashboard} />
          <Route path="/dashboard/transactions/" component={AdminDashboard} />
          <Route path="/dashboard/transactions/:id" component={AdminDashboard} />
          <Route path="/dashboard/invitations/" component={AdminDashboard} />
          <Route path="/dashboard/invitations/:id" component={AdminDashboard} />
          <Route path="/manage/" component={AdminDashboard} />
          <Route path="/manage/content/" component={AdminDashboard} />
          <Route path="/manage/content/:route" component={AdminDashboard} />
          <Route path="/manage/content/:route/:block" component={AdminDashboard} />
          <Route path="/manage/transactions/" component={AdminDashboard} />
          <Route path="/manage/transactions/:id" component={AdminDashboard} />
          <Route path="/manage/transactions/parent/:id" component={AdminDashboard} />
          <Route path="/manage/transactions/operator/:id" component={AdminDashboard} />
          <Route path="/manage/transactions/distributor/:id" component={AdminDashboard} />
          <Route path="/manage/transactions/broker/:id" component={AdminDashboard} />
          <Route path="/manage/transactions/queue/" component={AdminDashboard} />
          <Route path="/manage/transactions/queue/:id" component={AdminDashboard} />
          <Route path="/manage/operators/" component={AdminDashboard} />
          <Route path="/manage/operators/:id" component={AdminDashboard} />
          <Route path="/manage/distributors/" component={AdminDashboard} />
          <Route path="/manage/distributors/:id" component={AdminDashboard} />
          <Route path="/manage/brokers/" component={AdminDashboard} />
          <Route path="/manage/brokers/:id/" component={AdminDashboard} />
          <Route path="/manage/marketgroups/" component={AdminDashboard} />
          <Route path="/manage/marketgroups/:id" component={AdminDashboard} />
          <Route path="/manage/requests/" component={AdminDashboard} />
          <Route path="/manage/requests/:id" component={AdminDashboard} />
          <Route path="/manage/requests/account/:id" component={AdminDashboard} />
          <Route path="/manage/products/" component={AdminDashboard} />
          <Route path="/manage/products/:id" component={AdminDashboard} />
          <Route path="/manage/payoutrules/" component={AdminDashboard} />
          <Route path="/manage/payoutrules/:id" component={AdminDashboard} />
          <Route path="/manage/promotions/" component={AdminDashboard} />
          <Route path="/manage/promotions/:id" component={AdminDashboard} />
          <Route path="/manage/users/" component={AdminDashboard} />
          <Route path="/manage/users/:id" component={AdminDashboard} />
          <Route path="/manage/programs/" component={AdminDashboard} />
          <Route path="/manage/programs/:id" component={AdminDashboard} />
          <Route path="/manage/programs/:id/:view" component={AdminDashboard} />
          <Route path="/manage/invitations/" component={AdminDashboard} />
          <Route path="/manage/invitations/:id" component={AdminDashboard} />
          <Route path="/manage/program/" component={AdminDashboard} />
          <Route path="*" component={Error404} />
        </Route>
      </Router>
    );
  }
}

export default Root;
