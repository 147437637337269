import React from 'react';
import {assignIn} from 'lodash';
import PropTypes from 'prop-types';
import ga from 'react-ga4';
import Raven from 'raven-js';
window.Raven = Raven;
import {state, withState} from './stores';
import {Index} from './components';

class App extends React.Component {
  componentDidMount() {
    const {currentProgram} = this.props.s;

    if (process.env.NODE_ENV === 'production'
      && currentProgram?.ga_id) {
      ga.initialize(currentProgram.ga_id);

      state.connect({
        pathName: this.ga,
      });

      this.ga();
    }

    if (process.env.NODE_ENV === 'production') {
      window.Raven
        .config('https://0b413b5f98a547cd8c00e0e94d927d08@sentry.io/1187622', {
          dataCallback: (data) => {
            assignIn(data.extra, this.props.s);
            return data;
          }
        })
        .install();
    }
  }
  shouldComponentUpdate() {
    return !this.props.s.currentProgram || this.props.s.currentProgram.status === 'active';
  }

  ga = () => {
    ga.send(this.context.router.location.pathname);
  }

  render() {
    return (
      <Index {...this.props} router={this.context.router} />
    );
  }
}

App.contextTypes = {
  router: PropTypes.object.isRequired
};

App = withState(App)

export default App;