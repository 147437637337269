import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {state} from '../../stores/index';

export class PanelDefault extends React.Component {
  static defaultProps = {
    className: null,
    style: null,
    bodyStyle: null,
    header: null,
    headerStyle: {},
    onHeaderClick: null,
    headerCenterAlign: null,
    headingRight: null,
    footerLeft: null,
    footerRight: null,
    noBody: false,
    border: true,
    type: 'flat',
    content: false,
    onClick: null,
    onMouseEnter: null,
    onMouseLeave: null,
    width: window.innerWidth,
  };
  componentDidMount() {
    if (this.props.getWidth) {
      this.connectId = state.connect({
        width: () => {
          if (!this.props.width || !this.ref) return;
          this.props.getWidth(this.ref.clientWidth);
        }
      });
    }
  }
  componentWillUnmount() {
    if (this.props.getWidth) {
      state.disconnect(this.connectId);
    }
  }
  getRef = (ref) => {
    if (!ref) return;
    this.ref = ref;
    setTimeout(() => this.props.getWidth(ref.clientWidth), 500);
  }
  render() {
    let p = this.props;
    let defaultStyle = {};
    let headerStyle = _.assignIn({
      backgroundColor: p.type === 'default' ? '#FFF' : 'initial',
      borderColor: !p.border || p.noBody  || p.type !== 'default' ? 'rgba(0, 0, 0, 0)' : '#ddd',
      minHeight: '66px',
      fontSize: '16px'
    }, p.headerStyle);

    if (p.content || !p.border) {
      _.assignIn(defaultStyle, {
        boxShadow: p.type === 'default' ? '0 1px 3px rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0)' : 'initial'
      });
    }

    _.assignIn(defaultStyle, _.cloneDeep(p.style));

    return (
      <div
      ref={p.getWidth ? this.getRef : null}
      className={`panel panel-${p.type}${p.className ? ' '+p.className : ''}`}
      style={defaultStyle}
      onClick={p.onClick}
      onMouseEnter={p.onMouseEnter}
      onMouseLeave={p.onMouseLeave}>
        {p.header != null ?
        <div
        className="panel-heading"
        style={headerStyle}>
          <h6 className="panel-title" style={{fontSize: p.content ? '24px' : 'inherit', textAlign: p.headerCenterAlign ? 'center' : 'initial', color: p.onHeaderClick ? 'initial' : 'inherit'}}>
            {p.onHeaderClick ? <a style={{cursor: 'pointer'}} onClick={p.onHeaderClick}>{p.header}</a> : p.header}
          </h6>
          {p.headingRight ?
          <div className="heading-elements" style={{top: p.width < 769 ? '-3.5px' : '-12.5px', right: '0px', position: 'relative', float: 'right', fontSize: '15px'}}>
            {p.headingRight}
          </div> : null}
        </div> : null}

        {!p.noBody ?
        <div className="panel-body" style={p.bodyStyle}>
          {p.children}
        </div> : null}
        {p.noBody ? p.children : null}
        {p.footerLeft || p.footerRight ?
        <div className="panel-footer panel-footer-transparent">
          <div className="heading-elements">
            {p.footerLeft}
            <div className="pull-right">
              {p.footerRight}
            </div>
          </div>
        </div> : null}
      </div>
    );
  }
}

export class FeedPanel extends React.Component {
  static defaultProps = {
    items: []
  };
  componentDidMount() {
    let checkRef = () => {
      if (!this.listFeed) {
        setTimeout(checkRef, 500);
        return
      }
      $(this.listFeed).children().each((i, el) => {
        $(el).find('a').on('click', (e) => {
          e.preventDefault();
          this.props.router.push(e.target.href);
        });
      });
    };
    setTimeout(checkRef, 500);
  }
  render() {
    return (
      <div className="panel panel-body border-top-teal">
        <ul ref={(ref) => this.listFeed = ref} className="list-feed">
          {this.props.items.map((item, i) => {
            const createMarkup = ()=> { return {__html: item.description};};
            return (
              <li key={i}>
                <div dangerouslySetInnerHTML={createMarkup()} />
                <div className="text-muted">{_.upperFirst(moment(item.time).format('MMMM D, Y'))}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export class Tabs extends React.Component {
  static defaultProps = {
    options: [],
    active: 0
  };
  constructor(props) {
    super(props);
  }
  handleTabClick = (index) => {
    this.props.onClick(index);
  }
  render() {
    const p = this.props;
    return (
      <div className="tabbable">
        <ul className="nav nav-tabs nav-tabs-highlight nav-justified">
          {p.options.map((option, i) => {
            return (
              <li key={i} className={p.active === i ? 'active' : ''}>
                <a style={{cursor: 'pointer'}} className="legitRipple" onClick={() => this.handleTabClick(i)}>{option.label}</a>
              </li>
            );
          })}
        </ul>

        {p.children ?
        <div className="tab-content">
          {p.children}
        </div> : null}
      </div>
    );
  }
}

export class UserBox extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <PanelDefault className="UserBox">
        <div className="list-group-item">
          <div style={{fontWeight: '500'}}>User Profile: {this.props.s.currentUser.display_name}</div>
          <div>Account: {this.props.s.currentUser.account ? this.props.s.currentUser.account.name : 'Unassigned'}</div>
          {this.props.s.currentUser.status === 'active' && this.props.s.currentUser.permission > 1 ?
          <div style={{marginTop: '10px'}}>
            <a style={{cursor: 'pointer'}} onClick={() => this.props.router.push('/invite/send/')}>Invite Someone to Your Team</a>
          </div> : null}
        </div>
      </PanelDefault>
    );
  }
}