import React from 'react';
import {throttle} from 'lodash';
import {state, StateContext} from '../stores/index';
import {ajax} from '../services';
import {isMobile} from '../utils/utils';

const _syncView = (path) => {
  if (path.indexOf('/manage/') > -1) return;
  ajax.post('/sync/', {path});
};
const syncView = throttle(_syncView, 2000, {leading: false});

class BaseStoreContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(state, {
      isMobile: isMobile()
    });
    state.setState = (...args) => this.setState(...args);
    this.connectId = this.state.connect('*', (newState) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('STATE INPUT: ', newState);
        let stackParts = new Error().stack.split('\n');
        console.log('STATE CALLEE: ', stackParts[3].trim());
      }

      if (newState.pathName && newState.pathName !== this.state.pathName) {
        syncView(newState.pathName);
      }

      this.setState(newState, () => console.log('STATE: ', this.state));
    });
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
    state.disconnect(this.connectId);
  }
  handleResize = () => {
    this.state.set({width: window.innerWidth, height: window.innerHeight, isMobile: isMobile()});
    this.state.trigger('resize');
  }
  render() {
    return (
      <StateContext.Provider value={this.state}>
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

export default BaseStoreContainer;