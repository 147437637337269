export const determineView = (path, currentUser) => {
  let view, isDetailView = false;

  switch (true) {
    case (path[3] === 'queue'): {
      view = 'queue';
      break;
    }
    case ((path[1] === 'dashboard' && (path[2] === '' || path[2] === 'transfers')) || path[2] === 'transactions'): {
      view = 'transactions';
      break;
    }
    case ((path[1] === 'dashboard' && path[2] === 'profile') || path[2] === 'users'): {
      view = 'users';
      break;
    }
    case (path[2] === 'content' && (path[3] !== 'new' && (!path[3] || (path[3].length !== 36 && path[3] !== 'media'))) && currentUser.permission > 5): {
      view = 'pages';
      break;
    }
    case (path[2] === 'content' && path[3] === 'media' && currentUser.permission > 5): {
      view = 'media';
      break;
    }
    case (path[2] === 'marketgroups'): {
      view = 'tags';
      break;
    }
    default:
      view = path[2];
      break;
  }

  isDetailView = (((path[3] && path[3].length === 36))
    || (path[2] === 'transactions' && path[3] === 'queue' && path[4] && path[4].length === 36)
    || (view === 'payoutrules' && path[3] === 'new'))

  return {view, isDetailView};
}