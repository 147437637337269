export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

export const blockTypes = [
  'dashboard_title',
  'dashboard_body',
  'article_title',
  'article_body',
  'confirmation_title',
  'confirmation_body',
  'email_subject',
  'email_title',
  'email_body',
  'cta',
  'cta_url'
];

const dashboardPageType = [
  'dashboard_title',
  'dashboard_body',
];

export const ctaType = [
  'Article',
  'Internal',
  'External'
]

const articlePageType = [
  'dashboard_title',
  'dashboard_body',
  'article_title',
  'article_body',
  'cta_type',
  'cta'
];

export const pageTypes = {
  article: articlePageType,
  promotions: articlePageType,
  funding_request_ideas: articlePageType,
  funding_request_submission: dashboardPageType.concat([
    'confirmation_body'
  ]),
  registration: dashboardPageType.concat([
    'article_title',
    'confirmation_title',
    'confirmation_body',
    'email_subject',
    'email_title',
    'email_body',
    'invitation_subject',
    'invitation_title',
    'invitation_body',
    'cta'
  ]),
  terms_of_service: articlePageType,
  privacy_policy: articlePageType,
  user_agreement: articlePageType,
};

export const permissionLabels = [
  'Unapproved',
  'Operator',
  'DSR',
  'BSR',
  'RSM',
  'Client Manager',
  'Incent Manager',
  'Incent Admin',
  'System Admin'
];

export const accountLabels = [
  'Operator',
  'Distributor',
  'Broker'
];

export const accountLabelsLower = [
  'operator',
  'distributor',
  'broker'
];

export const userLabels = [
  'New/Inactive',
  'Operator',
  'DSR',
  'BSR',
  'RSM',
  'Client Manager'
];

export const userLabelsShort = [
  'N',
  'O',
  'D',
  'B',
  'R',
  'C'
];

export const accountTypeSelectOptions = [
  {
    icon: 'coffee',
    title: 'Operator',
  },
  {
    icon: 'location4',
    title: 'Distributor',
  },
  {
    icon: 'collaboration',
    title: 'Broker',
  }
];

export const userStatusLabels = [
  'New',
  'Active',
  'Suspended'
];

export const defaultConfirm = {
  content: '',
  open: false,
  continue: false
};

export const actionChoices = [
  {label: 'Base payout', key: 'base'},
  {label: 'Bonus payout', key: 'bonus'},
  {label: 'Modify bonus', key: 'bonus_modifier'},
];

export const transactionStatusMap = {
  processing: 'Processing',
  pending: 'Pending Payment',
  released: 'Released'
};

export const accountTypeMap = {
  operator: 'OperatorAccount',
  DSR: 'DistributorAccount',
  BSR: 'BrokerAccount',
};

export const determinedPerAccount = 'Determined per account';
export const currencyOptions = ['points', 'entries', 'dollars', determinedPerAccount]

export const imageSizeFixRegex = /\s(\d|\d\d|\d\d\d|\d\d\d\d)(?=("|;))/g;