import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import onClickOutside from 'react-onclickoutside';

import * as api from '../../services/index';
import {each, map, findIndex} from '../../utils/utils';
import {state} from '../../stores';

const menuItemStyle = {
  cursor: 'pointer',
  paddingLeft: '6px',
  paddingRight: '6px'
};

export class NavDropdown extends React.Component {
  static defaultProps = {
    label: '',
    options: null
  }
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      clickAction: false
    };
  }
  handleClickOutside = () => {
    this.setState({dropdownOpen: false});
  }
  handleClick = (e, onClick) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      clickAction: true
    }, () => {
      if (typeof onClick === 'function') {
        onClick(e);
      }
    });
  }
  render() {
    let p = this.props;
    let s = this.state;
    return (
      <li className={`dropdown dropdown-user${s.dropdownOpen ? ' open' : ''}`}>
        <a className="dropdown-toggle" data-toggle="dropdown" onClick={this.handleClick}>
          <span>{p.label}</span>
          <i className="caret" />
          {s.clickAction ? <span className="legitRipple-ripple" /> : null}
        </a>

        <ul className="dropdown-menu dropdown-menu-right">
          {p.options ? map(p.options, (option, i) => {
            if (option.divider) {
              return <li key={i} className="divider" />;
            }
            return option.argument ? (
              <li key={i} style={p.textAlignStyle}>
                <a style={{cursor: 'pointer'}} onClick={(e) => this.handleClick(e, option.onClick)}>
                  {option.icon ? <i className={`pull-right icon-${option.icon}`} /> : null}
                  {option.label}
                </a>
              </li>
            ) : null;
          }) : null}
        </ul>
      </li>
    );
  }
}

NavDropdown = onClickOutside(NavDropdown);

export class PrimaryNavbar extends React.Component {
  static defaultProps = {
    isDummy: false
  }
  constructor(props) {
    super(props);
    this.state = {
      menuItems: [],
      invisibleMenuItems: [],
      ready: false,
      banner: null,
      bannerThreshold: false,
      mobileOpen: false,
    };

    this.navbarRef = null;
    this.bannerHeight = 50;
  }

  componentDidMount() {
    if (this.props.isDummy) {
      return;
    }
    this.getMenuItems(this.props);
    this.getBanner(this.props);
    const checkCollapseRef = () => {
      if (!this.collapseRef) {
        setTimeout(() => checkCollapseRef(), 50);
        return;
      }

      setTimeout(this.handleNavHeight, 1000);
    };

    checkCollapseRef();

    this.lastCheckNavigation = this.props.s.checkNavigation;

    this.connectId = state.connect({
      width: this.handleNavHeight,
      currentUser: ({currentUser}) => {
        if (currentUser) return;
        this.setState({invisibleMenuItems: []});
      },
      currentProgram: () => {
        this.getMenuItems(this.props);
        //this.checkNavAdjustment();

        if (this.collapseRef) {
          this.getBanner(this.props);
        }
      },
      path: ({path}) => {
        if (this.collapseRef) {
          this.getBanner(this.props);
        }
        if (!path[1]) {
          document.addEventListener('scroll', this.handleScroll);
        }
      },
      checkNavigation: ({checkNavigation}) => {
        if (checkNavigation && this.collapseRef) {
          state.checkNavigation = false;
          this.checkNavAdjustment();
        }
      },
      isMobile: ({isMobile}) => {
        if (isMobile && !this.willUnmount) {
          this.setState({
            menuItems: this.state.menuItems.concat(this.state.invisibleMenuItems),
            invisibleMenuItems: []
          });
        } else {
          this.handleNavHeight();
        }

        setTimeout(this.handleNavHeight, 1000);
      }
    });
  }
  componentWillUnmount() {
    this.willUnmount = true;

    if (this.props.isDummy) return;

    state.disconnect(this.connectId);

    this.removeScrollListener();
  }
  handleClickOutside = () => {
    if (!this.state.mobileOpen) {
      return;
    }
    this.setState({mobileOpen: false});
  }
  checkNavAdjustment = () => {
    let count = -1;

    setTimeout(this.handleNavHeight, 0);

    const checkNavAdjustment = () => {
      setTimeout(() => {
        count++;
        if (count > 50) return;
        if (!this.handleNavHeight()) checkNavAdjustment();
      }, 100)
    };

    checkNavAdjustment();
  }
  handleNavHeight = () => {
    const p = this.props;
    let now = Date.now();
    let didCollapse = false;

    if (!this.collapseRef || this.willUnmount || (now - this.lastAdjustment) < 10) {
      return;
    }

    this.lastAdjustment = now;

    if (document.body.scrollHeight < window.innerHeight || p.s.isMobile) {
      state.set({checkNavigation: false});
      return;
    }

    const setState = () => {
      this.setState({
        menuItems: this.state.menuItems,
        invisibleMenuItems: this.state.invisibleMenuItems
      });
    };
    const removeLastMenuItem = () => {
      this.state.invisibleMenuItems = this.state.invisibleMenuItems.concat(this.state.menuItems.splice(-1, 1));
      didCollapse = true;
    };
    if (this.collapseRef.clientHeight > 50) {
      while (this.collapseRef.clientHeight > 50) {
        removeLastMenuItem();
        setState();
      }
    } else {
      while (this.collapseRef.clientHeight < 51 && this.state.invisibleMenuItems.length > 0 && this.lastWidth < p.s.width) {
        this.state.menuItems = this.state.menuItems.concat(this.state.invisibleMenuItems.splice(-1, 1));
        setState();
      }
    }
    if (this.collapseRef.clientHeight > 50) {
      removeLastMenuItem();
      setState();
    }

    this.lastWidth = p.s.width;

    return didCollapse;
  }
  removeScrollListener = () => {
    document.removeEventListener('scroll', this.handleScroll);
  }
  _getMenuItems = (p) => {
    if (this.props.isDummy) return;

    let {currentProgram, currentUser} = p.s;
    let {pages} = currentProgram;
    let items, index;

    if (!pages) return;

    pages = pages.slice();
    items = [];

    let showFundingRequestSubmission = (
      p.s.currentUserW
      && (p.s.currentUser.permission > 1 || p.s.currentUser.is_primary)
      && p.s.currentUser.account
      && !p.s.currentUser.account.uses_card
      && p.s.width >= 950
    );

    if (!showFundingRequestSubmission) {
      index = findIndex(pages, (page) => page.page_type === 'funding_request_submission');

      if (index > -1) pages.splice(index, 1);
    }

    if (currentUser) {
      index = findIndex(pages, (page) => page.page_type === 'funding_request_ideas');

      if (index > -1) {
        let page = pages[index];

        pages.splice(index, 1);
        pages = _.concat([page], pages);
      }
    }

    index = findIndex(pages, (page) => page.page_type === 'promotions');

    if (index > -1) {
      let page = pages[index];

      pages.splice(index, 1);
      pages = [page].concat(pages);
    }

    pages = _.orderBy(pages, 'priority_menu', 'asc');

    each(pages, (page) => {
      if (page.show_in_navigation) {
        items.push({
          label: page.label,
          slug: page.slug,
          id: page.id,
          argument: true,
          onClick: () => this.handleMenuItemClick(page),
          icon: null,
          divider: null
        });
      }
    });

    this.setState({menuItems: items}, () => setTimeout(this.checkNavAdjustment, 0) /* this.checkNavAdjustment */);
  }
  getMenuItems = (p) => {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.toLowerCase().indexOf('op') === -1) {
      setTimeout(() => this._getMenuItems(p), 25);
    } else {
      this._getMenuItems(p);
    }
  }
  getBanner = (p) => {
    if (p.s.path[1]) {
      $('.content-wrapper').css({paddingTop: '20px'});
      this.removeScrollListener();
      return;
    }
    this.handleScroll();
    document.addEventListener('scroll', this.handleScroll);
    if (p.s.isIE) {
      this.setState({banner: true});
      return;
    }
    axios.get(p.s.currentProgram.homepage_banner, {
      responseType: 'blob'
    }).then((res) => {
      let reader = new FileReader();
      reader.readAsDataURL(res.data);
      reader.onload = () => {
        this.setState({banner: reader.result});
      }
    })
  }
  handleScroll = () => {
    let bannerThreshold = window.pageYOffset < 159;
    if (bannerThreshold !== this.state.bannerThreshold) {
      this.setState({bannerThreshold});
      $('.content-wrapper').css({paddingTop: bannerThreshold ? '20px' : `${this.bannerHeight}px`});

      return;
    }

    this.bannerHeight = this.navbarRef.clientHeight;
  }
  handleAccountSwitch = (account) => {
    api.switchAccount({id: account.id}, (currentUser) => {
      state.set({currentUser}, () => {
        this.props.router.push('/dashboard/');
        if (this.props.s.currentUser.accounts.length > 1) {
          state.setAlert({
            content: `Switched the active account for this session to ${account.name}.`,
            tag: 'info'
          });
        }
      });
    })
  }
  handleBrandClick = () => {
    let route = '/';
    if (this.props.s.currentUser) {
      route = '/dashboard/';
    }
    this.props.router.push(route);
  }
  handleMenuItemClick = (item) => {
    this.props.router.push(`/content/${item.slug}/`);
    this.setState({mobileOpen: false});
  }
  handleDashboardClick = () => {
    this.props.router.push('/dashboard/');
    this.setState({mobileOpen: false});
  }

  handleNavbarRef = (ref) => {
    if (!ref) return;

    this.navbarRef = ref;
  }

  render() {
    let s = this.state;
    let p = this.props;
    let userOptions = [];
    let programOptions = [];
    let isAdminOrRep = p.s.currentUser && p.s.currentUser.permission > 1 && p.s.currentUser.status === 'active';
    let isSplash = !p.s.currentUser && p.s.path[1] === '' && !s.bannerThreshold;
    let isTopSplash = !p.s.currentUser && p.s.path[1] === '' && s.bannerThreshold;
    let mobile = p.s.width < 768;

    if (p.s.currentUser) {
      let hasMultipleAccounts = p.s.currentUser.accounts.length > 1 && p.s.currentUser.status === 'active' && p.s.currentUser.permission === 7;
      userOptions = [
        {
          argument: isAdminOrRep || p.s.width <= 933,
          onClick: () => p.router.push(isAdminOrRep ? '/manage/' : '/dashboard/'),
          icon: null,
          label: isAdminOrRep ? 'Incent Admin' : 'Dashboard',
          divider: null
        },
        {
          divider: isAdminOrRep
        },
        {
          argument: true,
          onClick: () => p.router.push(`/dashboard/profile/${p.s.currentUser.id}/`),
          icon: null,
          label: 'My Profile',
          divider: null
        },
        {
          divider: hasMultipleAccounts
        },
      ];

      if (hasMultipleAccounts) {
        each(p.s.currentUser.accounts, (account) => {
          if (account.programs.indexOf(p.s.currentProgram.id) > -1) {
            userOptions.push({
              argument: true,
              onClick: () => this.handleAccountSwitch(account),
              icon: account.id === p.s.currentUser.account.id ? 'check' : null,
              label: account.name,
              divider: null
            })
          }
        });
      }

      if (p.s.currentUser.permission > 1 && p.s.currentUser.status === 'active') {
        userOptions.push({
          divider: hasMultipleAccounts
        }, {
          argument: p.s.currentUser.hasInvitations === true,
          onClick: () => p.router.push('/dashboard/invitations/'),
          icon: null,
          label: 'Invitations',
          divider: null
        }, {
          argument: true,
          onClick: () => p.router.push('/invite/send/'),
          icon: null,
          label: 'Send Invite',
          divider: null
        });
      }

      userOptions.push({
        argument: true,
        onClick: () => api.logout(() => p.router.push('/')),
        icon: null,
        label: 'Logout',
        divider: null
      });

      if (p.s.currentProgram) {
        programOptions = [
          {
            argument: true,
            onClick: () => p.router.push('/manage/program/'),
            icon: null,
            label: 'Program Settings',
            divider: null
          }
        ];
      }
    }
    let splashBgStyle = {
      width: '100%',
      height: '100%',
      position: 'relative',
      backgroundImage: s.banner ? `url(${p.s.isIE ? p.s.currentProgram.homepage_banner : s.banner})` : 'initial',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      minHeight: '226px'
    };

    let navStyle = {
      position: isSplash ? 'fixed' : 'relative',
      padding: mobile ? '0px' : `0px ${p.s.path[1] === 'manage' ? '20px' : p.s.pagePadding}`,
      userSelect: 'none',
      width: '100%',
      zIndex: '90',
      left: 0,
      marginLeft: '0px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
      transition: 'background-color 0.1s',
      backgroundColor: isTopSplash && !p.s.isIE ? 'rgba(0, 0, 0, 0) !important' : p.s.currentProgram.data.theme.navBg,
      top: '0px'
    };

    if (isTopSplash) {
      _.assignIn(navStyle, splashBgStyle);
    } else if (isSplash) {
      delete navStyle.backgroundImage;
    }

    let collapseStyle = {transition: 'height 0.1s opacity 0.1s', opacity: isTopSplash ? '0' : '1'};
    let contentLinksStyle = {marginLeft: '0px', opacity: isTopSplash ? 0 : 1, transition: 'opacity 0.2s'};
    let textAlignStyle = {textAlign: mobile ? 'center' : 'initial'};

    _.assignIn(contentLinksStyle, textAlignStyle);
    return (
      <div className="navbar navbar-inverse bg-blue-800 nav-t" style={navStyle} ref={this.handleNavbarRef}>
        <div className="navbar-header nav-t" style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}>
          <a className="navbar-brand" style={{cursor: 'pointer'}} onClick={this.handleBrandClick}>
            <img
            src={isTopSplash ? p.s.currentProgram.logo : p.s.currentProgram.login_logo}
            style={
                !p.s.currentUser && p.s.path[1] === '' && s.bannerThreshold
                  ? {position: 'absolute', top: '50px', height: '150px', margin: '0px auto', left: '0px', right: '0px'}
                  : {position: 'relative', top: '-13px', height: '42px'}
              } />
          </a>
          {!isTopSplash ?
          <ul className="nav navbar-nav pull-right visible-xs-block">
            <li>
              <a data-toggle="collapse" onClick={() => this.setState({mobileOpen: !s.mobileOpen})}>
                <i className="icon-menu7" />
              </a>
            </li>
          </ul> : null}
        </div>

        <div ref={(ref) => !this.collapseRef ? this.collapseRef = ref : null} className={`navbar-collapse collapse${s.mobileOpen ? ' in' : ''}`} style={collapseStyle}>
          <ul className="nav navbar-nav content-links" style={contentLinksStyle}>
            {p.s.currentUser && p.s.currentUser.permission > 0 ?
            <li>
              <a style={menuItemStyle} onClick={this.handleDashboardClick}>
                Dashboard
              </a>
            </li> : null}
            {this.state.menuItems ? map(this.state.menuItems, (item) => {
              return (
                <li key={item.id}>
                  <a style={menuItemStyle} onClick={item.onClick}>
                    {item.label}
                  </a>
                </li>
              );
            }) : null}
            {this.state.invisibleMenuItems.length > 0 ?
            <NavDropdown s={p.s} router={p.router} label="More" options={this.state.invisibleMenuItems} /> : null}
          </ul>

          <ul className="nav navbar-nav navbar-right" style={textAlignStyle}>
            {p.s.currentUser ?
            <NavDropdown
            s={p.s}
            router={p.router}
            label={p.s.currentUser.display_name}
            options={userOptions}
            textAlignStyle={textAlignStyle} /> : null}
            {p.s.currentProgram.alt_logo.length > 0 ? (
              <a className="navbar-brand">
                <img
                src={p.s.currentProgram.alt_logo}
                style={
                    isSplash
                      ? {position: 'fixed', top: '8px', height: '48px', right: p.s.pagePadding, zIndex: '91'}
                      : {position: 'relative', top: '-13px', height: s.bannerThreshold && p.s.path[1] === '' ? '72px' : '42px'}
                  } />
              </a>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
}

PrimaryNavbar = onClickOutside(PrimaryNavbar);

export class NavBox extends React.Component {
  static defaultProps = {
    header: null,
    className: ''
  };
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      mobileOpen: false
    };
    window.__test = () => this.handleWidth(undefined)
  }
  componentDidMount() {
    this.connectId = state.connect({
      width: () => {
        if (!this.ref) return;
        setTimeout(() => this.handleWidth(this.ref), 0);
      }
    })
  }
  componentWillUnmount() {
    this.willUnmount = true;

    state.disconnect(this.connectId);
  }
  handleClickOutside = () => {
    if (!this.state.mobileOpen) {
      return;
    }
    this.setState({mobileOpen: false});
  }
  handleWidth = (ref = this.ref) => {
    let init = false;
    if (!ref) {
      return;
    }
    if (!this.ref) {
      init = true;
    }
    this.ref = ref;
    if (init) {
      setTimeout(() => {
        if (this.willUnmount) {
          return;
        }
        this.setState({width: ref.clientWidth});
      }, 0);
    } else if (!this.willUnmount) {
      this.setState({width: ref.clientWidth});
    }
  }
  render() {
    const {className} = this.props;
    let above426 = this.state.width > 444 || this.props.width < 502;
    let showHeader = above426 || this.props.isMobile;
    return (
      <div ref={this.handleWidth} className={`navBox navbar navbar-inverse bg-info-700 navbar-component ${className}`}>
        <div className="navbar-header">
          {showHeader ? <div className="navbar-brand">{this.props.header}</div> : null}

          <ul className="nav navbar-nav pull-right visible-xs-block">
            <li>
              <a
              className="legitRipple"
              onClick={() => this.setState({mobileOpen: !this.state.mobileOpen})}>
                <i className="icon-menu7" />
              </a>
            </li>
          </ul>
        </div>
        <div
        className={`navbar-collapse collapse${this.state.mobileOpen ? ' in' : ''}`}
        style={{backgroundColor: '#507591', width: `${this.state.width}px`, position: this.state.mobileOpen ? 'absolute' : 'initial'}}>
          <ul
          className={`nav navbar-nav${above426 ? ' navbar-right' : ''}`}
          style={showHeader ? null : {position: 'absolute', left: '0px', right: '0px', margin: '0px auto'}}>
            {map(this.props.options, (option, i) => {
              return (
                <li key={i}><a style={{cursor: 'pointer'}} className="legitRipple" onClick={option.onClick}>{option.label}</a></li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

NavBox = onClickOutside(NavBox);