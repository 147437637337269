import React from 'react';
import Loadable from 'react-loadable';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import {state} from '../stores';
import {each} from '../utils/utils';
import {sessionContent} from '../services';
import {PanelDefault} from './template/panels';

const retryButtonClasses = 'btn bg-blue-800 legitRipple';

const Loading = function(props) {
  if (props.error) {
    console.log(props.error);
    return <div>Error! <button className={retryButtonClasses} onClick={props.retry}>Retry</button></div>;
  }

  if (props.timedOut) {
    return <div>Taking a long time... <button className={retryButtonClasses} onClick={props.retry}>Retry</button></div>;
  }

  return null;
};

export const AsyncComponent = function(opts) {
  return Loadable(Object.assign({
    loading: Loading,
    delay: 2000,
    timeout: 10000,
  }, opts));
};

let ImageModal = AsyncComponent({
  loader: () => import(/* webpackChunkName: "imageModal" */ './template/modals/imageModal')
});

export const injectProps = function(WrappedComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      _.assignIn(props, this.props)
      return <WrappedComponent {...props} />;
    }
  }
};

export const installStateListener = function(ModifiedComponent) {
  const _setState = ModifiedComponent.prototype.setState;

  ModifiedComponent.prototype.beforeSetStateCallbacks = {};

  ModifiedComponent.prototype.setState = function setState(nextState, callback) {
    console.log(this.constructor.name + ' next state:', nextState);

    each(nextState, (value, key) => {
      if (typeof this.beforeSetStateCallbacks[key] === 'function') {
        this.beforeSetStateCallbacks[key].call(this, nextState);
      }
    });

    _setState.call(this, nextState, () => {
      if (typeof callback === 'function') callback.call(this);
      console.log(this.constructor.name + ' state:', this.state);
    });
  };

  return ModifiedComponent;
};

export const DashComponent = (props) => {
  return (
    <PanelDefault
    type="default"
    content={true}
    header={props.title}
    footerRight={props.cta}>
      <ReactMarkdown source={props.body} />
    </PanelDefault>
  );
}

export const getArticleComponent = function(pageType, pageTitle) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        title: null,
        body: null,
        image: ''
      }
    }
    componentDidMount(){
      let id = '';
      if (!this.props.onTitleChange && pageTitle) {
        state.set({pageHeader: pageTitle});
      }

      if (this.props.s.path[2] === 'preview' || this.props.s.preview) {
        this.setState(this.props.s.preview, () => {
          state.set({pageHeader: this.props.s.preview.title});
        });
        return;
      }

      each(this.props.s.currentProgram.pages, (page) => {
        if (page.slug === this.props.s.path[2]) {
          pageType = page.page_type;
          id = page.id;
        }
      });
      sessionContent(
        {pageTypes: [pageType], id},
        (err, newState) => {
          if (err) return;
          newState[pageType].id = id;
          this.setState(newState[pageType], () => {
            if (this.props.onTitleChange) {
              this.props.onTitleChange(this.state.title);
            }
            if (!this.props.onTitleChange && !pageTitle) {
              state.set({pageHeader: this.state.title});
            }
            this.getRef();
          });
        }
      );
    }
    handleImageModalClose = () => {
      this.setState({image: ''});
    }
    getRef = (ref) => {
      setTimeout(() => {
        $('.panel-body').find('img').each((i, el) => {
          $(el).css({cursor: 'pointer'});
          el.addEventListener('click', () => this.setState({image: $(el).attr('src')}));
        });
      }, 15);
    }
    render(){
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-12">
              {this.state.title && this.state.body ?
              <DashComponent
              pageType={pageType}
              title={!this.props.onTitleChange ? this.state.title : null}
              body={this.state.body} /> : null}
            </div>
          </div>
          {this.props.s.currentUser && this.props.s.currentUser.permission > 5 && this.state.id ?
          <div className="row">
            <a onClick={() => this.props.router.push(`/manage/content/${this.state.id}/`)}>Edit Page</a>
          </div>   : null}
          {this.state.image ?
          <ImageModal
          source={this.state.image}
          onClose={this.handleImageModalClose} /> : null}
        </React.Fragment>
      );
    }
  }
};